/* =============================================================
 * import
 * ========================================================== */
import {smoothScroll} from '../modules/common';
import {eventObserver} from "../modules/eventObserver";
import {intervalObserver} from "../modules/intervalObserver";
import {ScrollTrigger} from "gsap/ScrollTrigger";


/* =============================================================
 * function
 * ========================================================== */

// Barbaにて現在と同一ページのリンクをクリックした際リロードをしない設定
export function samePageNotReload() {

  const eventDelete = (e = this.elem) => {
    if (e.currentTarget.href === window.location.href) {

      // クリックイベントを停止
      e.preventDefault();
      e.stopPropagation();
      smoothScroll(e.currentTarget.hash.replace(/#/, ''));
      return;
    }
  };

  const links = [...document.querySelectorAll('a[href]')];
  let obj;
  const listeners = [];
  links.forEach(link => {
    obj = {
      elem: link,
      handleEvent: eventDelete,
    };
    link.addEventListener('click', obj, false);
    // eventObserver
    listeners.push([link, 'click', obj, false]);
  });

  eventObserver.observeEvents( listeners );
}


// ページ個別のメタ情報を書き換える処理
export const replaceHeadTags = target => {
  const head = document.head;
  const targetHead = target.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
  const newPageHead = document.createElement('head');
  newPageHead.innerHTML = targetHead;
  const removeHeadTags = [
    'meta[name=\'keywords\']',
    'meta[name=\'description\']',
    'meta[property^=\'fb\']',
    'meta[property^=\'og\']',
    'meta[name^=\'twitter\']',
    'meta[name=\'robots\']',
    'meta[itemprop]',
    'link[itemprop]',
    'link[rel=\'prev\']',
    'link[rel=\'next\']',
    'link[rel=\'canonical\']',
  ].join(',');
  const headTags = [...head.querySelectorAll(removeHeadTags)];
  headTags.forEach(item => {
    head.removeChild(item);
  });
  const newHeadTags = [...newPageHead.querySelectorAll(removeHeadTags)];
  newHeadTags.forEach(item => {
    head.appendChild(item);
  });
};


// GoogleAnalyticsにアクセス情報を送る処理
export const gaSend = arg => {
  if (arg.type === 'ga') {
    if (typeof ga === 'function') {
      ga('set', 'page', arg.path);  // eslint-disable-line no-undef
      ga('send', 'pageview');  // eslint-disable-line no-undef
    }
  } else { // gtag
    if (typeof gtag === 'function') {
      arg.account.forEach(e => {
        gtag('config', e, { // eslint-disable-line no-undef
          page_path: arg.path,
        });
      });
    }
  }
};


export const customPreventCheck = (elm, event) => {

  if (elm.hasAttribute('target')) {
    if (elm.getAttribute('target') == '_blank') {
      // console.log('targetBlank');
      return true;
    }
  }

  // 該当クラスに属していればBarbaを無効に
  const ignoreClasses = ['no-barba'];
  for (let i = 0; i < ignoreClasses.length; i++) {
    if (elm.classList.contains(ignoreClasses[i])) {
      // console.log('no-barba');
      return true;
    }
  }

  // アンカーリンクであり同一ページでなければPjaxを有効に
  const url = location.protocol + '//' + location.host + location.pathname;
  const dataHash = elm.dataset.href;
  const extractHash = elm.href.replace(/#.*$/, '');
  const hashIndex = elm.href.indexOf('#');
  let urlHash;

  if (elm.href.startsWith(location.protocol + '//' + location.host)) {

    // ハッシュか、data-scroll-hashを持つ場合
    if (hashIndex > -1 || (dataHash !== undefined && dataHash !== null && dataHash !== '')) {
      if (extractHash !== url) {
        // 他ページのアンカーリンクの場合
        return false; // pjax有効
      } else {
        // 同一ページ上でのアンカーリンクの場合
        if (event.type === 'click') {
          if (dataHash !== undefined && dataHash !== null && dataHash !== '') {
            urlHash = dataHash;
          } else {
            urlHash = elm.hash;
          }
          // const urlHash = elm.hash.replace(/#/, '');
          smoothScroll(urlHash, event); // スムーススクロールを実行
          return true; // pjax無効
        }
      }
      return false; // pjax有効
    }
  }

  // 拡張子が該当する場合はtarget='_blank'に
  if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(elm.href.toLowerCase())) {
    elm.setAttribute('target', '_blank');
    return true;
  }
};


/* ********************************************
 * resetAnyFunc
 * ページ読み込み時の処理リセット用関数
 * ****************************************** */
export function resetAnyFunc() {
  eventObserver.clearEvents();
  intervalObserver.clearIntervals();
  ScrollTrigger.getAll().forEach(trigger => trigger.kill());
}
