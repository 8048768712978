/* =============================================================
 * import
 * ========================================================== */
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {createLoadingSmokeAnimation} from "./smoke";
import {resetSmokeAnimation} from "./smoke";
// import {smoke} from "./smokeLoading";

gsap.registerPlugin(CustomEase);
CustomEase.create("slide", ".32, .95, 1.6, 1");


export function opening() {

  const opening = document.querySelector('#opening');
  if (opening) {

    const ico = document.querySelectorAll('.loading_ico');
    const logo = document.querySelector('.opening_logo');
    const cta = document.querySelector('.cta');
    cta.style.visibility = 'hidden';


    const tl = gsap.timeline({
      repeat: 0,
      duration: 1.5,
    });

    const loadingSmokeAnimation = createLoadingSmokeAnimation();
    const setSmokeAnimation = resetSmokeAnimation();

    tl
      .fromTo(ico, {
        autoAlpha: 0,
        y: 50,
      }, {
        autoAlpha: 1,
        y: 0,
        stagger: .7,
        duration: .8,
        ease: "bounce.out",
      }, ">")
      .fromTo(logo, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        duration: .5,
        ease: "expo.out",
      }, "=+.5")
      .fromTo(logo, {
        scale: 1.5,
      }, {
        scale: 1,
        duration: 1.2,
        ease: CustomEase.create("custom", "M0,0 C0.084,0.61 0.206,0.406 0.442,0.406 0.844,0.824 0.374,1 1,1 "),
      }, "<")
      .add(loadingSmokeAnimation, "=-.4")
      .to(opening, {
        backgroundColor: "rgba(255, 255, 255, 0)",
        duration: 1.5,
        ease: "fadeCurtain",
      }, "<")
      .to(ico, {
        autoAlpha: 0,
        stagger: 0,
        duration: .2,
        ease: "expo.out",
      }, "<")
      .to(opening, {
        autoAlpha: 0,
        duration: 2,
        ease: "expo.out",
      }, "<")
      .to(logo, {
        autoAlpha: 0,
        duration: 2,
        ease: "expo.out",
      }, "<1.5")
      .to(cta, {
        autoAlpha: 1,
        duration: 1,
      }, "<.5")
      .add(setSmokeAnimation, "<1")
    ;
  }

}
