/* =============================================================
 * import
 * ========================================================== */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import {gsap} from 'gsap';
import * as barbaOpt from './options';
import * as routeCommon from '../route/common';
import top from '../route/top';
import about from '../route/about';
import {globalNavi} from '../modules/gnav';
import {isMobile, smoothScroll} from '../modules/common';
import {CustomEase} from "gsap/CustomEase";
import * as transitionGlobal from './transition/global';
import {initParallax} from "../modules/parallax";
import * as scrollObserver from "../modules/scrollObserver";



/* =============================================================
 * variables
 * ========================================================== */

// アニメーション用の変数定義
const $body = document.body;
const domain = document.domain;
const $header = document.getElementById('header');

gsap.registerPlugin(CustomEase);


export default function barbaRun() {

  barba.use(barbaPrefetch);

  barba.init({
    debug: true,
    logLevel: 'debug',
    schema: {
      prefix: 'data-trans',
      wrapper: 'wrapper',
      container: 'container',
      namespace: 'namespace',
    },
    prevent: ({el, event}) => barbaOpt.customPreventCheck(el, event),
    // キャッシュを無効にするページ指定
    // cacheIgnore: [],
    views: [
      {
        namespace: 'home',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          top(data);
        },
      },
      {
        namespace: 'about',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          about();
        },
      },
    ],

    transitions: [
      // https://barba.js.org/docs/advanced/hooks/#Base-hooks
      {
        // 全ページ共通のトランジション
        name: 'global-transitions',
        // sync: true, //同時実行モード
        async leave(data) {
          const duration = 500;

          ttStart();
          barbaOpt.resetAnyFunc(data); // 各イベントトリガーのリセット
          transitionGlobal.leave(data, duration);

          await new Promise((resolve) => {
            return setTimeout(resolve, duration);
          });
        },
        async enter(data) {
          const duration = 10;

          globalNavi(data.next.url.path);
          transitionGlobal.enter(data);

          await new Promise((resolve) => {
            return setTimeout(resolve, duration);
          });
        },
        afterEnter() {
          scrollObserver.floating();
        },
        after(data) {
          const duration = 500;
          setTimeout(() => {
            ttEnd(data);
            transitionGlobal.after(data, duration);
          }, duration);
        },
      },
      /*
      {
        // 各ルールに応じた個別トランジションの設定
        name: 'custom-transitions',
        to: { // どのページへの遷移か
          namespace: [
            'company',
          ],
        },
        from: { // どのページからの遷移か
          namespace: [
            'home',
          ],
        },

        async leave(data) {
          // console.log(data);
        },

        async after(data) {
          // console.log(data);
        },
      },
      */
    ],

  });

  barba.hooks.beforeEnter(({next}) => {
    barbaOpt.replaceHeadTags(next);

    // barbaOpt.scrollAdjuster();
    // routeCommon.cursor();
    // routeCommon.cursorAnimation();
    if (domain === 'www.suewang.net') {
      const gaArg = {
        type: 'gtag',
        path: next.url.path,
        account: [
          'G-Z76DYQ2VWS',
        ],
      };
      barbaOpt.gaSend(gaArg);
    }
    // barbaOpt.snsBtnReload(next.url.href);
    routeCommon.before(next);
  });

  barba.hooks.afterEnter((data) => {
    routeCommon.after(data.next);
    barbaOpt.samePageNotReload();

    const trigger = data.trigger;
    let hash = '';

    if (trigger === 'barba') {
      hash = window.location.hash;
    } else if (trigger !== '' && trigger !== 'back' && trigger !== 'forward') {
      const urlHash = trigger.data;
      const dataHash = trigger.dataset.scrollHash;
      if (dataHash !== undefined && dataHash !== null && dataHash !== '') {
        hash = dataHash;
      } else
      if(urlHash !== undefined && urlHash !== null && urlHash !== '') {
        hash = urlHash;
      }
    }

    smoothScroll(hash); // スムーススクロールを実行
  });

  barba.hooks.after(() => {
    initParallax();
  });

  /*
   * Transitions
   *  beforeOnce, once, afterOnce, before,
   *  beforeLeave, leave, afterLeave, afterLeave,
   *  enter, afterEnter, after
   * Views
   *  beforeLeave, afterLeave, beforeEnter, afterEnter
   * */

}

// トランジション開始時の設定
function ttStart() {
  $body.classList.remove('loaded'); // ロード完了のclassを解除
  $body.classList.add('loading'); // ローディング用のclassを追加
  $header.classList.remove('scrolled'); // スクロール開始クラスを解除（初期化）
  $header.style.pointerEvents = 'none'; // ヘッダー（ナビゲーション含む）を操作不可に設定
}

// トランジション終了時の設定
function ttEnd(data) {

  $body.classList.remove('loading');
  $body.classList.add('loaded');
  $header.style.pointerEvents = 'auto';
  // $header.style.opacity = 1;。

  // 共通パーツの初期化（遷移時にスクロールイベントが発生しクラスの付与等が発生するため）
  const namespace = data.next.namespace; // barbajsからdataを取得
  const hum = document.querySelector('.hum');
  const headerLogo = document.querySelector('.header_logo');
  hum.classList.remove('negative');
  headerLogo.classList.remove('negative');
  $header.classList.remove('negative'); // ヘッダーのネガティブ表示を解除

  // topページのみheaderにnegative class をデフォルトで付与
  if (namespace === 'home') {
    $header.classList.add('negative');
  }

  if (isMobile()) {
    data.next.container.classList.add('touch_device');  // touch device時に各種アニメーション等々を消すためのクラス（遷移時）
  }

}

