/* =============================================================
 * import
 * ========================================================== */
import * as THREE from 'three';
import {gsap} from 'gsap';
import {CustomEase} from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);
CustomEase.create("fadeCurtain", ".25, .46, .45, .94");


/* =============================================================
 * smoke
 * ========================================================== */

let scene;
let camera;
let renderer;
const clock = new THREE.Clock();
const smokeParticles = [];
let smokeMaterial;

export function smoke() {
  return new Promise((resolve, reject) => {   // eslint-disable-line @typescript-eslint/no-unused-vars
    init();

    setLoading().then(() => {
      resolve();
    });
  });
}

function init() {

  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 5000);
  camera.position.set(0, 0, 5);
  scene.add(camera);
  renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector("#smoke"),
    antialias: true,
    alpha: true, // canvas自体を透明化
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  // renderer.setClearColor(new THREE.Color(0x000000, 0)); // 第二引数0で完全透明
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.useLegacyLights = false;

  /*
  const container = document.querySelector('#canvas_vr');

  container.appendChild(renderer.domElement);
  */

  window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }, false);
}


function setLoading() {
  return new Promise((resolve, reject) => {   // eslint-disable-line @typescript-eslint/no-unused-vars
    generateThreeWorld().then(() => {
      setLight();
      rendering();
      resolve();
    });
  });
}


function generateThreeWorld() {
  return new Promise((resolve, reject) => { // eslint-disable-line @typescript-eslint/no-unused-vars
    // 平面の形状を生成
    const smokeGeometry = new THREE.PlaneGeometry(30, 30);

    // テクスチャの設定
    const textureLoader = new THREE.TextureLoader();
    let smokeImage = '';
    if (window.location.hostname === 'shop.suewang.net') {
      smokeImage = 'https://www.suewang.net/assets/images/texture/smoke.png';
    } else {
      smokeImage = '/assets/images/texture/smoke.png';
    }
    const smokeTexture = textureLoader.load(smokeImage, function(texture) {  // eslint-disable-line @typescript-eslint/no-unused-vars

      // マテリアルの設定
      smokeMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff,
        map: texture,
        transparent: true,
        depthTest: false,
        opacity: .5,
      });

      // 平面をX、Y、Z軸方向にランダムに配置
      for (let i = 0; i < 7; i++) {
        const particle = new THREE.Mesh(smokeGeometry, smokeMaterial);
        particle.position.x = Math.random() * 30 - 15;
        // particle.position.y = Math.random() * 5 - 50;
        particle.position.y = -15;
        particle.position.z = Math.random() * 6 - 10;
        particle.rotation.z = Math.random() * 360;

        smokeParticles.push(particle);
        scene.add(particle);
      }

      resolve();  // Texture loaded and smokeMaterial set
    });
  });
}


function setLight() {
  const ambientLight = new THREE.AmbientLight(0xFFFFFF, 20);
  scene.add(ambientLight);

  const spotLight = new THREE.SpotLight(0xFFFFFF, 1, 13, Math.PI / 4, 10, 1);
  spotLight.position.set(0, 8, -5);
  spotLight.castShadow = true;
  scene.add(spotLight);

  const positionArr = [
    [-0.1, -0.4, 1.4, 5],
    [0, 0.9, 2, 10],
  ];

  for (let i = 0; i < positionArr.length; i++) {
    const pointLight = new THREE.PointLight(0xFFFFFF, positionArr[i][4], 2, 1);
    pointLight.position.set(positionArr[i][0], positionArr[i][1], positionArr[i][2]);
    scene.add(pointLight);
  }
}


function rendering() {
  const delta = clock.getDelta();
  let num = smokeParticles.length;

  // 平面をZ軸方向に回転
  while (num--) {
    smokeParticles[num].rotation.z += (delta * 0.03);
  }
  requestAnimationFrame(rendering);
  renderer.render(scene, camera);
}


export function createSmokeAnimation() {
  const smokeUpTl = gsap.timeline({
    paused: true,  // pause the timeline initially
    repeat: 0,
  });
  console.log('smokeMaterial' + smokeMaterial);
  smokeUpTl.add(
    gsap.to(smokeMaterial, {
      opacity: .8,
      duration: .3,
      ease: "fadeCurtain",
    }),
  );
  console.log('smokeParticles' + smokeParticles);
  smokeParticles.forEach(particle => {
    smokeUpTl.add(
      gsap.to(particle.position, {
        y: '+4',
        duration: 2,
        ease: "fadeCurtain",
      }),
      0,
    );
  });
  smokeUpTl.to(smokeMaterial, {
    opacity: 0,
    duration: 1,
    ease: "fadeCurtain",
  }, ">");

  return smokeUpTl;
}


export function setSmokeAnimation() {
  const smokeUpTl = gsap.timeline({
    paused: true,  // pause the timeline initially
    repeat: 0,
  });
  smokeUpTl.add(
    gsap.set(smokeMaterial, {
      opacity: 1,
      duration: .5,
      ease: "fadeCurtain",
    }),
  );

  smokeParticles.forEach(particle => {
    smokeUpTl.add(
      gsap.fromTo(particle.position, {
        y: -30,
      }, {
        y: -15,
        duration: 1.5,
        ease: "fadeCurtain",
      }),
      0,
    );
    gsap.to(smokeMaterial, {
      opacity: .5,
    });
  });

  return smokeUpTl;
}


export function resetSmokeAnimation() {
  const smokeUpTl = gsap.timeline({
    repeat: 0,
  });

  smokeParticles.forEach(particle => {
    smokeUpTl.add(
      gsap.fromTo(particle.position, {
        y: -30,
      }, {
        y: -15,
        duration: 1.5,
        ease: "fadeCurtain",
      }),
      0,
    );
  });

  smokeUpTl.add(
    gsap.to(smokeMaterial, {
      opacity: .5,
    }), "<",
  );

  return smokeUpTl;
}


export function createLoadingSmokeAnimation() {

  const loadingSmoke = gsap.timeline({
    repeat: 0,
  });

  loadingSmoke.add(
    gsap.to(smokeMaterial, {
      opacity: 0,
      duration: 1,
      ease: "fadeCurtain",
    }),
  );

  smokeParticles.forEach(particle => {
    loadingSmoke.add(
      gsap.fromTo(particle.position, {
        x: "random([1, 2, 3, -1, -2, -3])",
        y: "random([1, 2, 3, -1, -2, -3])",
      },{
        x: "random([-10, -11, -12, 10, 11, 12])",
        y: "random([-10, -11, -12, 10, 11, 12])",
        duration: 2,
        ease: "fadeCurtain",
      }), 0,
    );
  });

  loadingSmoke.add(
    gsap.to(smokeMaterial, {
      opacity: 1,
      duration: 2,
      ease: "fadeCurtain",
    }), "<",
  );

  loadingSmoke.add(
    gsap.to(smokeMaterial, {
      opacity: 0,
      duration: 1,
      ease: "fadeCurtain",
    }), ">",
  );

  return loadingSmoke;
}
