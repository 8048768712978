/* =============================================================
 * import
 * ========================================================== */
import {gsap} from 'gsap';
import {CustomEase} from "gsap/CustomEase";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {intervalObserver} from "./intervalObserver";
import {isMobile} from "./common";

gsap.registerPlugin(CustomEase, ScrollTrigger, ScrollSmoother);
CustomEase.create("fadeCurtain", ".25, .46, .45, .94");


let smoothScrub;
let normalizeScrollFlg;
let effectsFlg;

if (isMobile()) {
  smoothScrub = 0;
  normalizeScrollFlg = false;
  effectsFlg = false;
  ScrollTrigger.normalizeScroll(false);
  ScrollTrigger.config({
    limitCallbacks: true,
    ignoreMobileResize: true,
  });
} else {
  smoothScrub = 1.5;
  normalizeScrollFlg = true;
  effectsFlg = true;
}

// ScrollSmootherのオプション
export const ScrollSmootherOpt = { // eslint-disable-line @typescript-eslint/no-unused-vars
  wrapper: '.main',
  content: '.main_container',
  effects: effectsFlg,
  normalizeScroll: normalizeScrollFlg,
  ignoreMobileResize: true,
  smooth: smoothScrub,
  // smoothTouch: 0,
};

export let smoother;


// ScrollSmootherを初期化
if (isMobile()) {
  smoother = null;
  const main = document.querySelector('.main');
  main.classList.add('touch_device'); // touch device時に各種アニメーション等々を消すためのクラス（landing時）
} else {
  smoother = ScrollSmoother.create(ScrollSmootherOpt); // eslint-disable-line @typescript-eslint/no-unused-vars
}

/* =============================================================
 * function
 * ========================================================== */

export function initParallax() {
  if (!isMobile()) {
    ScrollSmoother.create(ScrollSmootherOpt);
  }

  offerFloater();
  floatingHeader();
  loopSvgAnimation();
  imageScrollEffectY();
  imageScrollEffectX();

  /*
    window.addEventListener('resize', () => {

      if (!isMobile()) {
        // PCリサイズ時
        scrollScaleImage();
        scrollScaleImageBusiness();
        floatingHeader();
        scrollTxtSwiper();
        scrollTxtClipper();
        loopSvgAnimation();
        imageScrollEffect();
      } else {

      }

      /!*
      const newIsPortrait = window.innerHeight > window.innerWidth;
      if (newIsPortrait !== isPortrait) {
        isPortrait = newIsPortrait;
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        setupAnimation();
      }
      *!/
    });
  */

  ScrollTrigger.refresh();
}


function floatingHeader() {

  const targets = gsap.utils.toArray(".dark");
  const header = document.querySelector('#header');
  const hum = document.querySelector('#hum');

  if (targets.length > 0) {

    targets.forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top top+=100',
        end: 'bottom top',
        scrub: true,
        onToggle: self => {
          if (self.isActive) {
            header.classList.add('negative');
            hum.classList.add('negative');
          } else {
            header.classList.remove('negative');
            hum.classList.remove('negative');
          }
        },
      });
    });
  }


  const kv = document.querySelector('#kv');
  const logo = document.querySelector('.header_logo');
  if (logo) {
    if (kv) {
      gsap.set(logo, {
        opacity: 0,
      });
      gsap.to(logo, {
        opacity: 1,
        scrollTrigger: {
          trigger: kv,
          // pin: true,
          // pinSpacing: true,
          // start: 'center center',
          start: 'bottom center',
          end: 'bottom top',
          // start: 'top center', // pin止めしない場合の設定
          // end: 'top top',      // pin止めしない場合の設定
          scrub: true,
          invalidateOnRefresh: true, // update on resize
        },
      });
    } else {
      gsap.to(logo, {
        opacity: 1,
      });
    }
  }

}


function offerFloater() {

  const target = document.querySelector('#offer');
  const footer = document.querySelector('#footer');

  // 実際のアニメーション設定
  if (target != null) {

    const box = target.querySelector('.offer_link');

    gsap.to(box, {
      // maskSize: "100% 100%", // 1600 -> 1920
      scrollTrigger: {
        trigger: target,
        pin: true,
        pinSpacing: true,
        // start: 'center center',
        start: 'bottom bottom',
        end: 'bottom top',
        // start: 'top center', // pin止めしない場合の設定
        // end: 'top top',      // pin止めしない場合の設定
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    /*
    // 上部の ONLINE SHOP テキスト
    const topContent = target.querySelector('.offer_desc_top');
    gsap.to(topContent, {
      x: () => -(topContent.offsetWidth),  // 横スクロールの長さを設定
      scrollTrigger: {
        // pin: true,
        trigger: target,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    // 下部の ONLINE SHOP テキスト
    const bottomContent = target.querySelector('.offer_desc_bottom');
    gsap.to(bottomContent, {
      x: () => (bottomContent.offsetWidth),  // 横スクロールの長さを設定
      scrollTrigger: {
        // pin: true,
        trigger: target,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });
    */

    // CTAボタン
    const cta = document.querySelector('.cta');
    gsap.to(cta, {
      y: () => ((window.innerHeight * -0.5) + (cta.offsetHeight * 0.5)), // 横スクロールの長さを設定
      x: () => ((window.innerWidth * -0.5) + (cta.offsetWidth * 0.5)), // 横スクロールの長さを設定
      scale: 1.5,
      scrollTrigger: {
        // pin: true,
        trigger: target,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    gsap.to(cta, {
      y: () => 0, // 横スクロールの長さを設定
      x: () => 0, // 横スクロールの長さを設定
      scale: 1,
      scrollTrigger: {
        // pin: true,
        trigger: footer,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });
  }


}


function loopSvgAnimation() {

  const target = document.querySelector('.cta_mei');
  const tl01 = gsap.timeline();

  if (target) {
    tl01
      .to(target, {
        y: -20,
        duration: .3,
      })
      .to(target, {
        y: 0,
        duration: .8,
        ease: 'bounce.out',
      }, ">")
    ;
  }


  const tl02 = gsap.timeline();

  if (target) {
    tl02
      .to(target, {
        rotate: -15,
        duration: .3,
      })
      .to(target, {
        rotate: 0,
        duration: .8,
        ease: 'bounce.out',
      }, ">")
    ;
  }


  const tl03 = gsap.timeline({
    duration: 1,
  });

  if (target) {
    tl03
      .to(target, {
        x: -20,
        duration: .3,
      })
      .to(target, {
        x: 0,
        duration: .8,
        ease: 'bounce.out',
      }, ">")
    ;
  }


  // タイムラインを配列に入れる
  const timelines = [tl01, tl02, tl03];
  let lastTimeline = null;

  // 配列からランダムなタイムラインを選ぶ関数
  const getRandomTimeline = (timelines) => {
    let randomIndex;
    let randomTimeline;

    do {
      randomIndex = Math.floor(Math.random() * timelines.length);
      randomTimeline = timelines[randomIndex];
    } while (randomTimeline === lastTimeline);

    lastTimeline = randomTimeline;
    return randomTimeline;
  };

  // 一定間隔（5000ミリ秒=5秒）でランダムなタイムラインを実行する
  intervalObserver.observerInterval(() => {
    const randomTimeline = getRandomTimeline(timelines);
    randomTimeline.restart();
  }, 10000);

}


function imageScrollEffectY() {
  // Parallax effect 1
  const pax1 = gsap.utils.toArray(".js-pax-outer");
  if (pax1.length > 0) {
    pax1.forEach(elm => {
      if (window.getComputedStyle(elm).getPropertyValue('display') === 'inline') {
        elm.style.display = 'inline-block';
      }
      const elmOuterHeight = elm.clientHeight;
      const elmInner = elm.querySelector(".js-pax-inner");
      const elmInnerHeight = elmInner.clientHeight;
      const elmDiffHeight = elmInnerHeight - elmOuterHeight;

      gsap.from(elmInner, {
        y: -elmDiffHeight,
        scrollTrigger: {
          trigger: elm,
          start: 'top bottom', // top-=100 bottom
          end: 'bottom top',
          scrub: 1,
          invalidateOnRefresh: true, // update on resize
        },
      });
    });
  }
}


function imageScrollEffectX() {
  // Parallax effect 1
  const pax1 = gsap.utils.toArray(".js-pax-outer-x");
  if (pax1.length > 0) {
    pax1.forEach(elm => {
      if (window.getComputedStyle(elm).getPropertyValue('display') === 'inline') {
        elm.style.display = 'inline-block';
      }
      const elmOuterWidth = elm.clientWidth;
      const elmInner = elm.querySelector(".js-pax-inner");
      const elmInnerWidth = elmInner.clientWidth;
      const elmDiffWidth = elmInnerWidth - elmOuterWidth;

      gsap.from(elmInner, {
        x: -elmDiffWidth,
        scrollTrigger: {
          trigger: elm,
          start: 'top bottom', // top-=100 bottom
          end: 'bottom top',
          scrub: 1,
          invalidateOnRefresh: true, // update on resize
        },
      });
    });
  }
}
