/* =============================================================
 * import
 * ========================================================== */
import {eventObserver} from "./eventObserver";
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {smoother} from "./parallax";
import {isMobile} from "./common";
// import {mediaQuery} from "./common";
import {createSmokeAnimation, setSmokeAnimation} from "./smoke";

gsap.registerPlugin(CustomEase);
CustomEase.create("slide", ".32, .95, .6, 1");
CustomEase.create("curtain", ".25, .43, .78, .71");
CustomEase.create("scroll", ".25, .43, .88, .71");
CustomEase.create("fadeCurtain", ".25, .46, .45, .94");


/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */

export function globalNavi() {
  const listElements = document.querySelectorAll('.header_nav_head_list_item_link');
  const childLink = document.querySelectorAll('.header_nav_bottom_list_item_link');
  const menu = document.querySelector('#menu');
  // const hum = document.querySelector('#hum');
  const logo = document.querySelector('.header_logo_link');
  const listeners = [];

  const addEvent = () => {

    // PC & SP
    if (menu) {
      // hum.addEventListener('click', humberger);
      menu.addEventListener('click', humberger);
      // logo.addEventListener('click', humberger);
      // listeners.push([hum, 'click', humberger]);
      listeners.push([menu, 'click', humberger]);
      // listeners.push([logo, 'click', humberger]);

      listElements.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
        listeners.push([elem, 'click', humberger]);
      });
      childLink.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
        listeners.push([elem, 'click', humberger]);
      });
    }

    eventObserver.observeEvents(listeners);
  };

  const removeEvent = () => {
    if (menu) {
      // hum.removeEventListener('click', humberger);
      menu.removeEventListener('click', humberger);
      logo.removeEventListener('click', humberger);
      listElements.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
      childLink.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
    }
  };

  addEvent();

  window.addEventListener('resize', function() {
    removeEvent();
    addEvent();
  });

  window.addEventListener('orientationchange', function() {
    removeEvent();
    addEvent();
  });

}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const tl = gsap.timeline({
    repeat: 0,
    ease: "curtain",
    // onComplete: kvSlider,
  });

  const html = document.documentElement;
  const hum = document.querySelector('#hum');
  const menu = document.querySelector('#menu');
  const bg = document.querySelector('.header_nav_bg');
  const header = document.querySelector('#header');
  const nav = document.querySelector('#nav');
  const cta = document.querySelector('.cta');
  const item = header.querySelectorAll('.__mask');
  // const itemBg = header.querySelectorAll('.__mask__bg');
  const logo = header.querySelector('.header_nav_logo');

  if (hum && menu) {

    if (hum.classList.contains('open')) {
      // 閉じる

      console.log('menu_closing');

      // スクロールを再開
      if (!isMobile()) {
        smoother.paused(false);
      } else {
        html.classList.remove('is-fixed');
        unlockScroll(); // スクロールのロックを解除
      }

      // class
      hum.classList.remove('open');
      menu.classList.remove('open');
      header.classList.remove('open');

      hum.classList.add('close');
      menu.classList.add('close');
      header.classList.add('close');
      menu.style.pointerEvents = 'none';
      gsap.to(hum, {opacity: 0});

      const smokeAnimation = setSmokeAnimation();
      tl.add(smokeAnimation.play());

      tl
        .fromTo(item, {
          opacity: 1,
          filter: 'blur(0px)',
        }, {
          opacity: 0,
          filter: 'blur(15px)',
          stagger: 0.05,
          duration: 0.4,
        }, "-=1.5")
        .fromTo(logo, {
          opacity: 1,
          filter: 'blur(0px)',
        }, {
          opacity: 0,
          filter: 'blur(15px)',
          duration: 0.4,
        }, "<")
        .call(toggleClass, [nav, 'animated'], "<")
        .fromTo(bg, {
          opacity: 1,
        }, {
          opacity: 0,
          duration: .9,
          ease: 'fadeCurtain',
          delay: 0,
          onComplete: function() {
            gsap.set(nav, {
              pointerEvents: 'none',
              visibility: 'hidden',
            });
            console.log('done');
          },
        }, "-=1")
        .set(menu, {
          pointerEvents: 'auto',
        }, ">")
        .to(hum, {
          opacity: 1,
        }, ">")
        .to(cta, {
          autoAlpha: 1,
          duration: 0.4,
        }, "<")
      ;

    } else {
      // 開く
      console.log('menu_opening');

      // スクロールを止める
      if (!isMobile()) {
        smoother.paused(true);
      } else {
        html.classList.add('is-fixed');
        lockScroll(); // bodyのスクロールをロック
      }

      // class
      hum.classList.remove('close');
      menu.classList.remove('close');
      header.classList.remove('close');

      hum.classList.add('open');
      menu.classList.add('open');
      header.classList.add('open');

      nav.style.visibility = 'visible';
      menu.style.pointerEvents = 'none';
      gsap.to(hum, {opacity: 0});
      gsap.to(cta, { autoAlpha: 0, duration: 0.4});

      const smokeAnimation = createSmokeAnimation();
      tl.add(smokeAnimation.play());

      tl
        .fromTo(bg, {
          opacity: 0,
        }, {
          opacity: 1,
          duration: .8,
          ease: 'fadeCurtain',
          onComplete: function() {
            gsap.set(nav, {
              pointerEvents: 'auto',
            });
          },
        }, "-=1.8")
        .fromTo(item, {
          opacity: 0,
          filter: 'blur(15px)',
        }, {
          opacity: 1,
          filter: 'blur(0px)',
          stagger: 0.05,
          duration: 0.4,
        }, "-=1.2")
        .call(toggleClass, [nav, 'animated'], "<")
        .fromTo(logo, {
          opacity: 0,
          filter: 'blur(15px)',
        }, {
          opacity: 1,
          filter: 'blur(0px)',
          duration: 0.4,
        }, "<")
        .set(menu, {
          pointerEvents: 'auto',
        }, ">")
        .to(hum, {
          opacity: 1,
        }, ">")
      ;

    }

  }

}


/*
function humbergerAnim(which) {

  const tl = gsap.timeline({
    repeat: 0,
    ease: "curtain",
  });

  const hum = document.querySelector('#hum');
  const humBtn = document.querySelector('.hum');
  const nav = document.querySelector('.header_content');
  const bg = document.querySelector('.hum_bg');
  const list = document.querySelectorAll('.header_gnav_list_item');
  const listBottom = document.querySelectorAll('.header_touchpoint_cta_link');
  const copyright = document.querySelector('.header_copyright_txt');

  if (which === 'open') {

    // open animation
    gsap.set(bg, {
      autoAlpha: 1,
    });
    gsap.set(nav, {
      autoAlpha: 1,
    });

    tl
      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
        duration: .6,
      }, "<")

      .fromTo(bg, {
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .8,
      }, "<")

      .call(toggleClass, [hum, 'ing'], ">")

      .fromTo(list, {
        x: '30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
        stagger: {
          each: .05,
        },
      }, ">-=.6")

      .fromTo(listBottom, {
        x: '30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
        stagger: {
          each: .05,
        },
      }, ">-=.5")

      .fromTo(copyright, {
        x: '30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
      }, "-=.6")

      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .8,
      }, "<")
    ;

  } else {

    // close animation

    tl
      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
        duration: .8,
      }, "<")

      .fromTo(list, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        // x: '-30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .5,
        stagger: {
          each: .05,
        },
      }, "<+=0")

      .fromTo(listBottom, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
      }, {
        // x: '-30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .5,
      }, ">-=.5")

      .fromTo(copyright, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        // x: '-30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .5,
      }, ">-=.5")

      .fromTo(bg, {
        // webkitClipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0%, 50% 0%, 50% 100%, 100% 100%, 100% 0%)',
        // clipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0%, 50% 0%, 50% 100%, 100% 100%, 100% 0%)',
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        // webkitClipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 0%)',
        // clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 0%)',
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .8,
      }, ">-=.2")

      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .6,
      }, ">")

      .set(nav, {
        autoAlpha: 0,
        zIndex: 0,
      }, ">")

      .set(bg, {
        autoAlpha: 0,
      }, ">")

      .call(toggleClass, [hum, 'ing'], ">")
    ;

  }
}
*/


/* =============================================================
 * toggleClass
 * ========================================================== */

function toggleClass(e, n) {
  e.classList.toggle(n);
}


/* =============================================================
 * lockScroll
 * ========================================================== */
let scrollPosition = 0;

function lockScroll() {
  scrollPosition = window.pageYOffset;
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPosition}px`;
}

function unlockScroll() {
  document.body.style.removeProperty('overflow');
  document.body.style.removeProperty('position');
  document.body.style.removeProperty('top');
  window.scrollTo(0, scrollPosition);
}
