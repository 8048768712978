export const intervalObserver = {
  intervals: [],

  // setIntervalを追加し、IDを保存
  observerInterval(callback, interval) {
    const id = setInterval(callback, interval);
    this.intervals.push(id);
  },
  clearIntervals: function(){
    this.intervals.forEach((id) => {
      clearInterval(id);
    });
    this.intervals = [];
  },
};
