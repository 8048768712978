/* =============================================================
 * import
 * ========================================================== */

import Swiper from 'swiper/bundle';
import {mediaQuery} from '../modules/common';

/* =============================================================
 * function
 * ========================================================== */
let swiper;

export default function exe() {

  productSwiper();

  window.addEventListener('resize', function() {
    productSwiper();
  });

}


function productSwiper() {

  const windowWidth = window.innerWidth;

  const initSwiper = () => {
    swiper = new Swiper('.about_concept', { // eslint-disable-line @typescript-eslint/no-unused-vars
      wrapperClass: 'about_concept_images',
      slideClass: 'about_concept_figure',
      slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
      centeredSlides : true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      loop: true,  // 無限ループさせる
      loopAdditionalSlides: 2, // 無限ループさせる場合に複製するスライド数
      speed: 6000, // スライドアニメーションのスピード（ミリ秒）
      initialSlide: 1, // スライドの開始位置
      grabCursor: true, // PCでマウスカーソルを「掴む」マークにする
      watchSlidesProgress: true, // スライドの進行状況を監視する
      preventClicks: true, // true: スワイプ中の不要なクリックを無効化
      preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化
      freeMode: {
        enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。
        momentum: true, // 有効にすると、スライドを離した後もしばらくの間、スライドが動き続けます
        // momentumRatio: 1, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
        // momentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
        sticky: false,  // スライド位置へのスナップの有効無効
      },
      mousewheel: {
        forceToAxis: true,
      },
    });
  };

  const destroySwiper = () => {
    if (swiper) {
      swiper.destroy();
      swiper = null;
    }
  };

  const handleSwiper = () => {
    if (mediaQuery(windowWidth) === 'pc' && swiper) {
      destroySwiper();
    } else if (mediaQuery(windowWidth) === 'sp' && !swiper) {
      initSwiper();
    }
  };

  handleSwiper();
}
