/* =============================================================
 * import
 * ========================================================== */
import TextWrap from "./textWrap";
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import Bowser from "bowser";
import {slideToggle} from "./slide";
import {eventObserver} from "./eventObserver";

gsap.registerPlugin(CustomEase, ScrollToPlugin);


/* =============================================================
 * mediaQuery
 * レスポンシブ判定
 * ========================================================== */
const windowWidth = window.innerWidth;
export function mediaQuery(width = windowWidth) {
  if (width <= 768) {
    return 'sp';
  } else {
    return 'pc';
  }
}


/* =============================================================
 * isMobile
 * タッチデバイス（SP、タブレット含む）の判別
 * ========================================================== */
export const isMobile = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isTouchDevice = 'ontouchstart' in window;
  if (browser.getPlatformType(true) === 'tablet' || browser.getPlatformType(true) === 'mobile' || isTouchDevice === true) {
    return true;
  } else {
    return false;
  }
};


// =============================================================
// smoothScroll
// スムーズなスクロールの処理
// =============================================================
export function smoothScroll(hash, event) {

  if (event !== undefined) {
    event.preventDefault(); // barabaでの非同期遷移後に、#付きリンクがクリックされると、スクロールイベントによって通常のスクロールが発生することを防ぐ
  }

  const hum = document.querySelector('#hum');

  // 調整値の計算
  const header = document.querySelector('#header');
  const offset = header.offsetTop + header.clientHeight * 2;

  let target;
  if (hash !== undefined && hash !== null && hash !== '') {
    target = document.querySelector(hash);

    // smoother.scrollTo(target, true, "top " + offset + "px");
    // ↑ スマホの慣性スクロールを切った状態だとスムーズスクロールにならないため ↓ を使う
    gsap.to(window, { duration: 1, scrollTo: {y: target, offsetY: offset}});

  } else {
    window.scroll(0, 0);
    header.classList.remove('scrolled');
    hum.classList.remove('scrolled');
  }
}


// =============================================================
// scrollResetOnLoad
// 読み込み時のスクロール位置リセット
// =============================================================
export function scrollResetOnLoad(hash = window.location.hash) {

  if (hash !== '' && mediaQuery() === 'pc') {
    // PCの場合、通常のスクロールが動き、位置がずれてしまうので、強制的に戻す。
    // SPの場合、こちらを入れると、逆にずれてしまうので、不要。
    window.scroll(0, 0);
  }

  // locoScroll.update(); // 慣性スクロールのリセット

}


// =============================================================
// モーダルウィンドウ
// =============================================================
/*
export function modalWindow() {

  if ($('#vimeo_video').length) {
    const player = new Player('vimeo_video', {
      url: 'https://vimeo.com/!***!/!***',
      // id: ***,
      width: 640,
    });

    $('.modal').each(function() {
      $(this).on('click', function() {
        const target = $(this).attr('data-target');
        const modal = document.getElementById(target);
        $(modal).fadeIn().css('display', 'flex');
        player.play().then(function() {
          console.log('再生');
        });
        return false;
      });
    });

    // 閉じるボタンを押した時の処理
    $('.modal_close').on('click', function() {
      $('.modal_area').fadeOut();
      // 閉じるボタンを押すとvimeo動画停止
      player.pause().then(function() {
        console.log('一時停止');
      });
      return false;
    });
  }

  if ($('#searchBtn').length) {
    $('#searchBtn').on('click', function() {
      const modal = $('#searchArea');
      const input = $('#s');
      $(modal).fadeIn().css('display', 'flex');
      $(input).focus();
      return false;
    });
    $('#searchAreaBg').on('click', function() {
      const modal = $('#searchArea');
      $(modal).fadeOut();
      return false;
    });
  }

}
*/


// =============================================================
// 指定テキストのspan囲み
// =============================================================
export function txtUp(elm = [...document.querySelectorAll(".js-txt-up")]) {
  elm.forEach((e) => {
    new TextWrap(e);
  });
}


// =============================================================
// スクロールバーなどを除いた画面の高さを正確に取得（主にスマホデバイス用
// =============================================================
export function setHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}


// =============================================================
// 開閉ボタン
// =============================================================
export function slideDownBtn() {

  const listeners = [];
  let obj;
  const btn = [...document.querySelectorAll('.js-slideBtn')];
  const area = "js-slideCnt";
  const speed = 500;
  const slide = (e) => {
    const thisElm = e.currentTarget;
    const nextElm = e.currentTarget.nextElementSibling;

    thisElm.classList.toggle('is-open');
    if (nextElm.classList.contains(area)) {
      nextElm.classList.toggle('is-open');
      slideToggle(nextElm, speed);
    }
  };

  if (btn.length > 0) {
    btn.forEach(elem => {
      obj = {
        elem: elem,
        handleEvent: slide,
      };
      elem.addEventListener('click', obj, false);
      listeners.push([elem, 'click', obj, false]);
    });
  }

  eventObserver.observeEvents( listeners );
}
