/* =============================================================
 * import
 * ========================================================== */
import {eventObserver} from "../modules/eventObserver";
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
// import {mediaQuery, isMobile} from "../modules/common";

gsap.registerPlugin(CustomEase);
CustomEase.create("syu", ".645, .045, .355, 1");


/* =============================================================
 * export function
 * ========================================================== */
export function before() {
  const $body = document.body;
  $body.classList.remove('top_page');
  $body.classList.add('sub_page');
}


export function after() {
  const listeners = [];
  // mouseStoker();

  const btn = document.querySelectorAll('.totop');
  if (btn.length > 0) {
    btn.forEach(elem => {
      elem.addEventListener('click', toTop, false);
      listeners.push([elem, 'click', toTop, false]);
    });
  }

  eventObserver.observeEvents(listeners);
}


/* =============================================================
 * function
 * ========================================================== */

export function toTop() {
  gsap.to(window, {duration: 1, scrollTo: 0});
}



/*
function mouseStoker() {

  if (!isMobile()) {
    const cursor = document.getElementById("cursor");
    const pos = {x: window.innerWidth / 2, y: window.innerHeight / 2}; // カーソル要素の座標（リサイズを考慮し初期位置を画面中央に設定）
    const mouse = {x: pos.x, y: pos.y}; // マウスカーソルの座標
    const speed = 0.5; // 0.01〜1 数値が大きほど早い

    // カーソルの座標を設定する
    const cursorSetX = gsap.quickSetter(cursor, "x", "px");
    const cursorSetY = gsap.quickSetter(cursor, "y", "px");

    const mouseStokerCallback = (pos) => {

      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      cursorSetX(pos.x);
      cursorSetY(pos.y);

    };

    // マウスカーソル座標を取得する
    document.addEventListener("mousemove", function(event) {
      mouse.x = event.pageX;
      mouse.y = event.pageY;
    });

    // マウスカーソル座標を更新する
    window.addEventListener("resize", function() {
      pos.x = window.innerWidth / 2;
      pos.y = window.innerHeight / 2;
      gsap.ticker.remove(mouseStokerCallback);
      gsap.ticker.add(function() {
        mouseStokerCallback(pos);
      });
    });

    // レンダリングする
    gsap.ticker.add(function() {
      mouseStokerCallback(pos);
    });
  }
}
*/
