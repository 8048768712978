export const eventObserver = {
  handlers: [],

  observeEvents: function(targets){
    this.handlers.push(targets);
  },
  clearEvents: function(){
    const _self = this; // eslint-disable-line @typescript-eslint/no-this-alias
    function loop(i){
      return new Promise(function(resolve, reject){ // eslint-disable-line @typescript-eslint/no-unused-vars
        const events = _self.handlers[i];
        // handlers[i]内のイベントを削除
        for (let j = events.length - 1; j >= 0; j--) {
          const remEv = events[j];
          const option = remEv[3] ? remEv[3] : false;
          if (option === '$') {
            // jQuery
            remEv[0].off(remEv[1]);
          } else {
            // native
            remEv[0].removeEventListener(remEv[1], remEv[2], option);
          }
        }
        // handlers[i]を削除
        _self.handlers.splice(i,1);
        resolve( i++ );
      }).then(function( count ){
        if( count < _self.handlers.length - 1 ){ // handlersのlength分loop()を回す
          loop( count );
        }
      });
    }
    loop(0);
  },
};
