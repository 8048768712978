/* =============================================================
 * import
 * ========================================================== */


/* =============================================================
 * function
 * ========================================================== */
// let swiper;

export default function exe(data) {
  const namespace = data.current.namespace; // barbajsからdataを取得

  // 遷移元ページが存在しない場合（TOPページにランディングした場合）のみ実行
  if (namespace === '') {
    // opening();
  }

}


