/* =============================================================
 * import
 * ========================================================== */
import 'intersection-observer';


/* =============================================================
 * function
 * ========================================================== */
export function floating() {
  // floatingHeader();
  scrollContentsObserver();
}

/*
function floatingHeader() {

  // intersection-observerAPIだと、
  // ページ遷移時に要素の重なりを検知してしまい、
  // 誤作動が起きるため、以下の書き方。
  const header = document.querySelector('#header');
  const hum = document.querySelector('#hum');
  const main = document.querySelector('.main');
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.remove('scrolled');
        hum.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
        hum.classList.add('scrolled');
      }
    });
  }, observerOptions);

  observer.observe(main);
}
*/


function scrollContentsObserver() {

  const target = document.querySelectorAll('.js-txt-up, .js-txt-view, .js-scroll-observe');
  const targetArray = Array.prototype.slice.call(target);

  // オプション設定
  const options = {
    root: null,
    rootMargin: '0px 0px',
    threshold: 0,
  };

  const observer = new IntersectionObserver(callback, options);
  targetArray.forEach((tgt) => {
    observer.observe(tgt);
  });

  let index = 0;
  function callback(entries) {
    entries.forEach(function(entry) {
      const target = entry.target;
      if (entry.isIntersecting && !target.classList.contains('is-active')) {
        const delay = index * 100;
        setTimeout(function() {
          target.classList.add('is-active');
        }, delay);
        index++;
      }
    });
  }
}


