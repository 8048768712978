/* =============================================================
 * import
 * ========================================================== */
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {txtUp} from "../../modules/common";
const tl = gsap.timeline();


/* =============================================================
 * functions
 * ========================================================== */
export function leave(data, duration = 1000) {
  const ms = duration / 1000;

  /*
  gsap.set(data.current.container, {
    autoAlpha: 1,
  });
  */

  gsap.to(data.current.container, {
    autoAlpha: 0,
    duration: ms,
    ease: "Power2.easeOut",
  });

}


export function enter(data) {

  gsap.set(data.next.container, {
    autoAlpha: 0,
  });
}


export function after(data, duration = 1000) {
  const ms = duration / 1000;

  txtUp([...data.next.container.querySelectorAll(".js-txt-up")]);

  tl
    .fromTo(
      data.next.container,
      {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        ease: CustomEase.create("custom", ".645, .045, .255, 1"),
        duration: ms,
      },
    )
  ;

  const ttl = data.next.container.querySelector(".mv");
  // const ttlJa = data.next.container.querySelector("[data-scroll-section]:first-child .ttl_ja");
  // const ttlEn = data.next.container.querySelector("[data-scroll-section]:first-child .ttl_en");
  if (ttl) {
    ttl.classList.add('is-active');
  }
  // ttlJa.classList.add('is-active');
  // ttlEn.classList.add('is-active');
}

