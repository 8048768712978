import $ from 'jquery';

// =============================================================
// adjusterIE
// IE調整
// =============================================================
export default function adjusterIE() {
  // fix画像のスクロール時カクツキを解消
  if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
    $('body').on('mousewheel', function() {
      event.preventDefault();
      const wd = event.wheelDelta;
      const csp = window.pageYOffset;
      window.scrollTo(0, csp - wd);
    });
  }
}
